import React, { FormEvent, useEffect, useState } from "react";
import "./home.css";
import axios from "axios";
import Header from "./header";
import AdditionalInfoSection from "./additionalInfomation";
import {
  Button,
  Col,
  Container,
  Alert,
  Row,
  Table,
  Spinner,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { StudentCommencementDetails } from "../models/StudentCommencementDetails";
import { useMsal } from "@azure/msal-react";

export const HomePage = () => {
  const { accounts, instance } = useMsal();
  const [token, setToken] = useState<string>("");
  const now = new Date();
  const dateOne = new Date(2024, 11, 1);
  //const dateOne = new Date();
  //const dateOne = new Date(); //For testing uncommwnt this version of dateOne and comment the above version to make window start from current time
  dateOne.setHours(8, 0, 0);
  console.log(dateOne);
  const dateTwo = new Date(2025, 4, 1);
  dateTwo.setHours(23, 59, 0);
  console.log(dateTwo);
  const [alertText, setAlertText] = useState<string>("");
  const [optOut, setOptOut] = useState<boolean>(true);
  const [formData, setFormData] = useState<StudentCommencementDetails[]>();
  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [commencementFN, setCommencementFN] = useState<string>("");
  const [commencementMN, setCommencementMN] = useState<string>("");
  const [commencementLN, setCommencementLN] = useState<string>("");
  const [editableWindow] = useState(
    now >= dateOne && now <= dateTwo ? true : false
  );
  const selectOptIn = () => setOptOut(false);
  const selectOptOut = () => setOptOut(true);
  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setCommencementFN(formData![0].StudentRecordFirstName);
      setCommencementMN(formData![0].StudentRecordMiddleName);
      setCommencementLN(formData![0].StudentRecordLastName);
    } else {
      setCommencementFN(
        formData![0].CommencementFirstName === null
          ? formData![0].StudentRecordFirstName
          : formData![0].CommencementFirstName
      );
      setCommencementMN(
        formData![0].CommencementMiddleName === null
          ? formData![0].StudentRecordMiddleName
          : formData![0].CommencementMiddleName
      );
      setCommencementLN(
        formData![0].CommencementLastName === null
          ? formData![0].StudentRecordLastName
          : formData![0].CommencementLastName
      );
    }
  };

  const handleSubmit = (
    event: FormEvent<HTMLFormElement>,
    stuInfo: StudentCommencementDetails
  ) => {
    const userId = accounts[0]?.username;
    event.preventDefault();
    const commencementFirstName = optOut
      ? null
      : isChecked
      ? commencementFN
      : stuInfo.StudentRecordFirstName;

    const commencementMiddleName = optOut
      ? null
      : isChecked
      ? commencementMN
      : stuInfo.StudentRecordMiddleName;

    const commencementLastName = optOut
      ? null
      : isChecked
      ? commencementLN
      : stuInfo.StudentRecordLastName;
    const commencementDisplayName =
      commencementFirstName === null
        ? null
        : commencementFirstName.concat(
            " ",
            commencementMiddleName === null ? "" : commencementMiddleName,
            " ",
            commencementLastName === null ? "" : commencementLastName
          );
    const putUrl = `https://commencementdataapi.azurewebsites.net/api/CommencementNames/CommencementName/${
      userId.split("@")[0]
    }`;
    const commencementObj = {
      CommencementFirstName: commencementFirstName,
      CommencementMiddleName: commencementMiddleName,
      CommencementLastName: commencementLastName,
      CommencementNameOptOut: optOut,
      CommencementDisplayName: commencementDisplayName,
    };
    const alertMessageText = optOut
      ? "Your selection is saved!"
      : `Your selection is saved! Your commencement name is ${commencementDisplayName}`;
    axios
      .put(putUrl, commencementObj, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setAlertText(alertMessageText);
      })
      .catch((err) => {
        setAlertText("Sorry! There was an error, try again later.");
      });
  };

  useEffect(() => {
    const apiRequest = {
      url: "https://commencementdataapi.azurewebsites.net/api/CommencementNames/StudentNames",
      scopes: [
        "api://ef36d957-61aa-435c-b29b-5fefa5a3ef0e/CommencmentDataApi.Read",
      ],
    };
    instance
      .acquireTokenSilent({
        ...apiRequest,
        account: accounts[0],
      })
      .then((res) => {
        setToken(res.accessToken);
        const userId = accounts[0]?.username;
        const getUrl = `https://commencementdataapi.azurewebsites.net/api/CommencementNames/StudentNames/${
          userId.split("@")[0]
        }`;
        axios
          .get(getUrl, {
            headers: { Authorization: `Bearer ${res.accessToken}` },
          })
          .then((res) => {
            setFormData(res.data);
            setLoading(false);
            if (res.data[0].CommencementNameOptOut) {
              setOptOut(true);
            } else {
              setOptOut(false);
            }
          })
          .catch((err) => console.log(err));
      });
  }, [accounts, instance]);

  const initializeSignOut = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
      postLogoutRedirectUri: "https://commencementdataapi.azurewebsites.net",
    };

    instance.logoutRedirect(logoutRequest);
  };
  if (loading) {
    return (
      <>
        <Header />
        <Container>
          <Row>
            {/* <Col sm={{ span: 8, offset: 6 }} md={{ span: 6, offset: 4 }}> */}
            <Col
              xs={{ span: 2, offset: 2 }}
              sm={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 4 }}
              lg={{ span: 4, offset: 4 }}
            >
              <Spinner className="spinning" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return formData && formData.length !== 0 ? (
    <>
      <Header />
      <Container className="main-container">
        <Col className="col-10">
          <Row>
            <Col className="mt-1">
              {editableWindow ? (
                <>
                  <p>
                    The University of Washington will publish a Commencement
                    program this Spring listing the names and degrees of
                    graduates from Summer 2023 through Winter 2024, and the
                    projected graduates from Spring 2024. Your legal(record)
                    name and degree will be listed in the program unless you
                    request otherwise. Due to program publication deadlines, you
                    must submit your request by 11:59 PM PDT on May 1st, 2025.
                    This deadline is firm and cannot be extended.
                  </p>
                  <p>
                    Please use this form to update your "Commencement Name" that
                    will be printed on the{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.washington.edu/ceremony/commencement-program/"
                    >
                      Commencement Program
                    </a>{" "}
                    Please note that this form does not update any other UW
                    systems or register you for commencement. Each UW System and
                    department manages there own commencement related process
                    including dates and details (which are subject to change).
                    Please see the "FAQ/Additional resources" section below for
                    more information.
                  </p>
                  <Table
                    className="tbl-stu-info"
                    bordered
                    responsive
                    variant="secondary"
                  >
                    <thead>
                      <tr>
                        <th>Record Name</th>
                        <th>Preferred Name</th>
                        <th>Degree Title</th>
                        <th>Quarter</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.map((s) => (
                        <tr key={s.CommencementNameId}>
                          <td>{s.RecordName}</td>
                          <td>{s.PreferredName}</td>
                          <td>{s.GraduationDegree}</td>
                          <td>{s.GraduationYq}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row className="mt-3">
                    <label className="qstn-lbl">
                      Do you wish to have your name listed in the UW
                      Commencement Program?
                    </label>
                    <Form
                      className="mt-2"
                      onSubmit={(e) => handleSubmit(e, formData[0])}
                    >
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          name="select-listing-option"
                          id="select-listing"
                          className="radio-btn"
                          onChange={selectOptIn}
                          label="Yes"
                          checked={optOut === false}
                        />
                        <Form.Check
                          type="radio"
                          name="select-listing-option"
                          id="select-listing"
                          className="radio-btn"
                          onChange={selectOptOut}
                          checked={optOut === true}
                          label="No (note that a request to not be listed in Commencement program cannot be revoked)"
                        />
                      </Form.Group>
                      {!optOut && (
                        <>
                          <label className="mt-3">
                            Your UW record name (as it appears on your
                            unofficial transcript) will be used in your
                            Commencemnt program listing unless you specify
                            otherwise below.
                          </label>
                          <Form.Group className="mt-3">
                            <Form.Check
                              type="switch"
                              id="toggle-switch"
                              className="switch-btn"
                              label="Do you want to use another name for your commencement program listing?"
                              checked={isChecked}
                              onChange={handleToggle}
                            />
                          </Form.Group>
                          <Col className="col-6 mt-3">
                            {isChecked ? (
                              <div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formCommencementName"
                                >
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ): void =>
                                      setCommencementFN(
                                        e.target.value === ""
                                          ? formData[0].StudentRecordFirstName
                                          : e.target.value
                                      )
                                    }
                                    name="commencementFirstName"
                                    defaultValue={
                                      formData[0].CommencementFirstName === null
                                        ? formData[0].StudentRecordFirstName
                                        : formData[0].CommencementFirstName
                                    }
                                    disabled={!editableWindow}
                                    required={isChecked}
                                  />
                                  <Form.Label>Middle Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ): void =>
                                      setCommencementMN(e.target.value)
                                    }
                                    name="commencementMiddleName"
                                    defaultValue={
                                      formData[0].CommencementMiddleName ===
                                      null
                                        ? formData[0].StudentRecordMiddleName
                                        : formData[0].CommencementMiddleName
                                    }
                                    disabled={!editableWindow}
                                  />
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ): void =>
                                      setCommencementLN(e.target.value)
                                    }
                                    name="commencementLastName"
                                    defaultValue={
                                      formData[0].CommencementLastName === null
                                        ? formData[0].StudentRecordLastName
                                        : formData[0].CommencementLastName
                                    }
                                    disabled={!editableWindow}
                                    required={isChecked}
                                  />
                                  <Form.Text className="text-muted">
                                    Enter your name as you want it to appear in
                                    the Commencement Bullieten.
                                  </Form.Text>
                                </Form.Group>
                              </div>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </>
                      )}
                      <Row className="mt-5" md={10}>
                        <Row>
                          <Alert show={alertText !== ""} variant="light">
                            <p>{alertText}</p>
                          </Alert>
                        </Row>
                        <Col xs={6}>
                          <Button
                            className="mt-3 save-btn"
                            variant="outline-primary"
                            type="submit"
                            size="lg"
                          >
                            Save
                          </Button>
                        </Col>
                        <Col xs={6} className="float-right">
                          <Button
                            className="mt-3 save-btn logout-btn"
                            variant="outline-primary"
                            size="lg"
                            type="button"
                            onClick={initializeSignOut}
                          >
                            Logout
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </>
              ) : (
                <>
                  {/* <p>
                    The Commencement Name edit window was between July 1st 2024
                    8 AM to May 1st 2025 11.59 PM for the current year. You can
                    now just view the commencement information.
                  </p> */}
                  <p>
                    The Commencement Name edit window is between July 1st 8 AM
                    to May 1st 11.59 PM every year. You can now just view the
                    commencement information.
                  </p>
                  <Row>
                    <Form className="mt-2">
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={formData[0].StudentRecordFirstName}
                            defaultValue={
                              formData[0].CommencementFirstName === " " ||
                              formData[0].CommencementFirstName === ""
                                ? formData[0].StudentRecordFirstName
                                : formData[0].CommencementFirstName
                            }
                            disabled={!editableWindow}
                          />
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={formData[0].StudentRecordMiddleName}
                            defaultValue={
                              formData[0].CommencementMiddleName === " "
                                ? formData[0].StudentRecordMiddleName
                                : formData[0].CommencementMiddleName
                            }
                            disabled={!editableWindow}
                          />
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={formData[0].StudentRecordLastName}
                            defaultValue={
                              formData[0].CommencementLastName === " "
                                ? formData[0].StudentRecordLastName
                                : formData[0].CommencementLastName
                            }
                            disabled={!editableWindow}
                          />
                        </Form.Group>
                      </div>
                      <Row>
                        <Col md={{ span: 6, offset: 4 }}>
                          <Button
                            className="mt-3 save-btn logout-btn"
                            variant="outline-primary"
                            size="lg"
                            type="button"
                            onClick={initializeSignOut}
                          >
                            Logout
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </>
              )}
              <Row>
                <AdditionalInfoSection />
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  ) : (
    <>
      <Header />
      <Container className="main-container mt-3">
        <Row>
          <Col
            md={{ span: 10, offset: 1 }}
            className="col-10 justify-content-center"
          >
            <div>
              <p>
                This form is used to capture name changes for students in UW
                graduate programs who will be included in the Commencement
                Bulletin for the upcoming graduation ceremony in June. Our
                system indicates that you do not meet these criteria and are not
                eligible [or do not qualify] to use this form.
              </p>
              <p>
                If you believe this is an error, please contact gshelp@uw.edu
                for assistance.
              </p>
            </div>
          </Col>
          <Row>
            <AdditionalInfoSection />
          </Row>
        </Row>
      </Container>
    </>
  );
};
